@import '../node_modules/@angular/material/theming';
@include mat-core();

$my-primary: mat-palette($mat-green, 500);
$my-accent: mat-palette($mat-teal, 500);
$my-theme: mat-light-theme($my-primary, $my-accent);

@include angular-material-theme($my-theme);


@font-face {
  font-family: "clean-ui";
  src: url(./assets/fonts/gordita.woff) format("woff");
  font-weight: normal;
}

.drag-scroll-content::-webkit-scrollbar {
  display: none;
}

:host {
  ::ng-deep {
    .mat-form-field-appearance-outline .mat-form-field-outline{
      border-width: 1px !important;
    }
    .mat-form-field {
      .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
        border-width: 1px !important;
      }
    }
  }

  mat-error {
    font-size: .9rem;
  }
}

ion-button.button-outline {
  --border-width: 1px;
}

.border-1 { --border-width: 1px; }

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  min-height: 100% !important;
}

.d-block {display: block !important;}
.d-inline-block {display: inline-block;}
.d-inline {display: inline;}
.d-flex {display: flex;}
.d-inline-flex {display: inline-flex !important;}
.flex-column {flex-direction: column;}
.flex-1 {flex: 1;}

.gap-sm {
  gap: .5rem;
}

.gap-md {
  gap: 1rem;
}

.no-margin-x {
  margin-left: 0;
  margin-right: 0;
}

.pointer {
  cursor: pointer;
}

.font-1 {
  font-size: 1rem;
}

.font-sm {
  font-size: .8rem !important;
}

.font-md {
  font-size: 1.5rem;
}

.no-margin-y {
  margin-top: 0;
  margin-bottom: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0;
}

.no-padding-top {
  padding-top: 0 !important;
}

.v-middle {
  vertical-align: middle;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-y {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-light {
  background-color: var(--ion-color-light);
}

.bg-transparent {
  background-color: transparent;
}

.bg-primary {
  background-color: var(--ion-color-primary);
}

.bg-danger {
  background-color: var(--ion-color-danger);
}

.text-light {
  color: var(--ion-color-light);
}

.text-primary {
  color: var(--ion-color-primary);
}

.text-danger {
  color: var(--ion-color-danger);
}

.text-dark {
  color: var(--ion-color-dark);
}

.px-sm {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.p-sm {
  padding: .5rem !important;
}

.py-sm {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.pt-sm {
  padding-top: .5rem !important;
}

.pb-sm {
  padding-bottom: .5rem !important;
}

.pl-sm {
  padding-left: .5rem !important;
}

.pr-sm {
  padding-right: .5rem !important;
}

.mt-sm {
  margin-top: .5rem !important;
}

.mb-sm {
  margin-bottom: .5rem !important;
}

.ml-sm {
  margin-left: .5rem !important;
}

.mr-sm {
  margin-right: .5rem !important;
}

.mx-sm {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-sm {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.bg-grey {
  background-color: var(--ion-color-medium-light);
}

.p-relative {
  position: relative;
}

.truncate, .truncate-1 {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: initial !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
  }
}

.truncate-2 {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: initial !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

.truncate-3 {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: initial !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
  }
}

// buttons
.btn-md {
  height: 2.5rem;
}

.btn-grey {
  --background: var(--ion-color-grey);
  --color: var(--ion-color-dark);
}

.btn-padding-sm {
  --padding-end: .8rem;
  --padding-start: .8rem;
  --box-shadow: none;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.round-md {
  border-radius: 1rem;
}

.fw-bold {
  font-weight: bold;
}

*:focus {outline: none !important;}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

.searchbar-input.sc-ion-searchbar-md {
  box-shadow: 0 0 0 0;
  background-color: #fafafa;
}

ion-searchbar.store-search{
  .searchbar-input{
    border-radius: 90px !important;
  }
}

.searchbar-outline {
  --background: transparent;
  --border-radius: 2rem;
  .searchbar-input{
    border: 1px solid var(--ion-color-light-shade) !important;
    background: transparent;
    box-shadow: none;
  }
}

.swipe-rounded {
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0px 6px 13px -5px rgb(0 0 0 / 28%);
}

// ion-modal.auto-modal {
//   &.bottom {
//       align-items: flex-end;
//   }

//   --height: auto;

//   .ion-page {
//       position: relative;
//       display: block;
//       contain: content;

//       .inner-content {
//           max-height: 100vh;
//           overflow: auto;
//           padding: 10px;
//       }
//   }
// }

.modal-round {
  --border-radius: 1.5rem 1.5rem 0 0;
}

ion-popover.alert {
  --min-width: 50vw;
  --max-width: 100vw;

  @media (max-width: 700px) {
  --min-width: 70vw;
  }

  @media (max-width: 580px) {
    --min-width: 85vw;
  }

}


.otp-input {
  background-color: var(--ion-color-grey) !important;
  border-radius: 14px !important;
  margin-bottom: 4px !important;
  -webkit-text-security: disc;
  // width: calc(( (1 - (.05 * var(--otp-length))) / var(--otp-length) ) * 100%) !important;

  &.tp-input {
    height: 40px !important;
    width: 40px !important;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-right: 5% !important;
  }


  @media (max-width: 767px) {
    &.pin-input {
      // background-color: #ddd !important;
      height: 50px !important;
      width: 50px !important;
      border-radius: 14px !important;
    }
  }

  @media only screen and (max-width: 540px) {

    &.tp-input {
      height: 45px !important;
      width: 45px !important;
      border-radius: 11px !important;
    }

    &.tp-input:not(:last-child) {
      margin-right: 6px !important;
    }
  }

  @media only screen and (max-width: 339px) {
    &.otp-x-input {
      height: 45px !important;
      width: 45px !important;
    }

    &.otp-x-input:not(:last-child) {
      margin-right: 4% !important;
    }
  }


  @media only screen and (max-width: 400px) {
    &.tp-input {
      height: 45px !important;
      width: 45px !important;
      border-radius: 11px !important;
    }

    &.tp-input:not(:last-child) {
      margin-right: 6px !important;
    }

    @media only screen and (max-width: 295px) {
      &.pin-input {
        height: 40px !important;
        width: 40px !important;
      }

    }


  }
}


.min-h-100 {
  min-height: 100%;
}